/* bottom sheet container 설정 */
.react-modal-sheet-container{
    border-top-right-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
    text-align: center;
    height : auto !important;
}

/* bottom sheet backdrop 설정 */

.react-modal-sheet-backdrop{
    background: rgba(255,255,255,0.1) !important;
}
/* bottom sheet 스크롤 없애기 */

.react-modal-sheet-scroller::-webkit-scrollbar{
    display:none !important;
}
.react-modal-sheet-scroller{
    -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}