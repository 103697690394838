html{
  margin: 0 auto;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  font-size:10px;
}
/* 
@media (min-width:720px){
  html{
    font-size:15px;
  }
} */

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
textarea:focus, input:focus{
  outline: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  text-align: center;
  margin:0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'Thin';
  src: url('./assets/font/NotoSansKR-Thin.otf');
}

@font-face {
  font-family: 'Light';
  src: url('./assets/font/NotoSansKR-Light.otf');
}

@font-face {
  font-family: 'DemiLight';
  src: url('./assets/font/NotoSansKR-DemiLight.otf');
}

@font-face {
  font-family: 'Regular';
  src: url('./assets/font/NotoSansKR-Regular.otf');
}

@font-face {
  font-family: 'Medium';
  src: url('./assets/font/NotoSansKR-Medium.otf');
}

@font-face {
  font-family: 'SemiBold';
  src: url('./assets/font/NotoSansKR-Regular.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Bold';
  src: url('./assets/font/NotoSansKR-Bold.otf');
}

@font-face {
  font-family : 'MonoRegular';
  src : url('./assets/font/NotoSansMonoCJKkr-Regular.otf');
}


@font-face {
  font-family : 'MonoBold';
  src : url('./assets/font/NotoSansMonoCJKkr-Bold.otf');
}

